import marker from "../../../../src/images/marker.svg";
import phone from "../../../../src/images/phone.svg";
import fax from "../../../../src/images/fax.svg";
import mail from "../../../../src/images/mail.svg";
import heart from "../../../../src/images/heart.svg";
import * as React from 'react';
export default {
  marker,
  phone,
  fax,
  mail,
  heart,
  React
};